<nav class="navbar navbar-transparent navbar-color-on-scroll fixed-top navbar-expand-lg" color-on-scroll="100" id="sectionsNav"  [@scrollAnimation]="state">
    <div class="container">
        <a class="navbar-brand" href="https://demos.creative-tim.com/material-kit/index.html">
          Fitz Of Code </a>
        <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="sr-only">Toggle navigation</span>
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
        </button> -->
        <a type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-bars"></i>
        </a>         
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" routerLink="/home" href="javascript:void(0)">
              <i class="fa fa-home"></i> Home
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/articles" href="javascript:void(0)">
              <i class="fa fa-th"></i> Blog
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/about" href="javascript:void(0)">
              <i class="fa fa-address-card"></i> About
            </a>
          </li>
          <li class="nav-item">
          </li>
          <li class="nav-item">
            <a class="nav-link" rel="tooltip" title="" data-placement="bottom" href="https://twitter.com/FitzOfCode" target="_blank" data-original-title="Follow us on Twitter" rel="nofollow">
              <i class="fa fa-twitter"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" rel="tooltip" title="" data-placement="bottom" href="https://www.facebook.com/FitzOfCode" target="_blank" data-original-title="Like us on Facebook" rel="nofollow">
              <i class="fa fa-facebook-square"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" rel="tooltip" title="" data-placement="bottom" href="https://www.youtube.com/channel/UCNLHzQE20sWxQGxbEPKJeSg" target="_blank" data-original-title="Follow us on Instagram" rel="nofollow">
              <i class="fa fa-youtube"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>